<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Axios from "../../../config/axios";
import store from "../../../state/store";

export default {
  props: ["app_settings"],
  data() {
    return {
      fields: {
        exchange_rate: "",
      },
    };
  },
  watch: {
    app_settings(value) {
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(this.fields, key)) {
          this.fields[key] = value[key];
        }
      }
    },
  },
  methods: {
    handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      Axios.post("/admin/app-settings/update-exchange-rate", this.fields)
        .then(() => {
          alert("Modification enregistrée !");
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
};
</script>

<template>
  <form action="javascript:void(0);" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-12  mb-4">
        <div class="d-block flex-shrink-0 me-3 mb-2">
          <span class="fs-16 bg-info shadow p-2">
            1.000.000 XAF = 
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="phoneNumber"
          placeholder="Montant (RMB)"
          v-model="fields.exchange_rate"
        />
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="submit" class="btn btn-primary">Modifier</button>
          <button type="button" class="btn btn-soft-success">Annuler</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>
